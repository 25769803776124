.container {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap:50px;
	margin: 40px 0;
	width: 100% !important;
	height: 100% !important;
}

.container .card {
	position: relative;
	width: 300px;
	height: 400px;
	
	margin: 20px;
	box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
	border-radius: 15px;
	display: flex;
	
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(10px);
}

.container .card .content {
	position: relative;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
    height: 90%;
}

.container .card:hover .content {
	transform: translateY(-200px);
}

.container .card .content .imgBx {
	position: relative;
	width: 150px;
	height: 150px;
	overflow: hidden;
}

.container .card .content .imgBx img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.container .card .content .contentBx h3 {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	margin: 20px 0 10px;
	line-height: 1.1em;
}

.container .card .content .contentBx h3 span {
	font-size: 12px;
	font-weight: 300;
	text-transform: initial;
}

.container .card .sci {
	position: absolute;
	bottom: 50px;
	display: flex;
	flex-direction: column;
}

.container .card .sci li {
	list-style: inside;
	margin: 0 5px;
	transform: translateY(40px);
	transition: 0.5s;
	opacity: 0;
}

.container .card:hover .sci li {
	transform: translateY(-40px);
	opacity: 1;
	color: #fff;
	font-size: 15px;
}
.overlay{
	background: rgba(0, 0, 0, 0.3);
	height: 100%;
	width: 100%;
	border-radius: 15px;
}

