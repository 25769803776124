 /* bring in normalize.css styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  html {
    font-family:
      'Roboto Flex',
      'Noto Sans',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
} */



@font-face {
  font-family: 'IvyMode';
  font-style: normal;
  font-weight: normal;
  src: url('../public/fonts/IvyMode-Light.ttf') ;
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
  src: url('../public/fonts/Gotham-Black.otf') ;
}
@font-face {
  font-family: 'Samarkan';
  font-style: normal;
  font-weight: normal;
  src: url('../public/fonts/SamarkanNormal-Gg5D.ttf') ;
}

body {
  margin: 0;
}

html,
body {
  margin: 0;
  padding: 0;
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-handle-last-row::after {
  content: '';
  flex: auto;
}

.custom-font-cursive {
  font-family: cursive;
}

.layout {
  height: calc(var(--vh, 1vh) * 100);
}

h1 {
  @apply text-md sm:text-md md:text-lg;
}

h2 {
  @apply text-base sm:text-md md:text-md;
}

h3 {
  @apply text-sm sm:text-md md:text-sm;
}

h4 {
  @apply text-md sm:text-md md:text-md;
}

h5 {
  @apply text-xs sm:text-xs md:text-xs;
}

h6 {
  @apply text-xs sm:text-xs md:text-xs;
}

.scrolling-text-container {
  overflow: hidden;
  white-space: nowrap;
}

.scrolling-text {
  display: inline-block;
  animation: scroll-left 10s linear infinite;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.elevate {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.writing-mode-vertical-lr {
  writing-mode: vertical-lr;
}
.swiper-button-prev:after,.swiper-button-next:after{
  display: none;
}
.container img{
  height: 100%;
  width: 100%;
}
.container{
  height: 250px;
  width: 300px;
  margin:auto;
}
@media screen and (max-width: 600px) {
.swiper-slide{
width:50% !important;
}
.scene {
  width: 150px !important;
  height: 100px !important;
  perspective: 6500px !important;
  position: relative;
  margin-top: 50px;
}
.carousel__cell {
  position: absolute;
  width: 150px !important;
  left: 0px !important;
  height: 100px !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.scene {
  width: 200px;
  height: 150px;
  perspective: 5000px;
  position: relative;
  margin-top: 50px;
}
.container .card{
  width:300px !important;
}
}
.wrapper {
  display: flex;
  justify-content: center;
}
.scene {
  width: 250px;
  height: 200px;
  perspective: 2500px;
  position: relative;
  margin-top: 50px;
}
.scene .carousel.keen-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
  
  transform-style: preserve-3d;
  margin-top:50px;
  margin-bottom: 100px;
}
.carousel__cell {
  position: absolute;
  width: 250px;
  left: 10px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
@media screen and (min-width: 767px) {
  .mobileview{
  display: none !important;
  }
}